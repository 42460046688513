'use client';

import { useMemo, useState } from 'react';
import clsx from 'clsx';
import Image, { StaticImageData } from 'next/image';
import { Swiper } from './Swiper';
import { Icon, Line } from '@/components';
import { Filter, FilterKey } from '@/types/Filters';
import {
  Altenar,
  AppsFlyer,
  Arland,
  BtoBet,
  CaptainUp,
  Fastrack,
  Firebase,
  Jumpman,
  Kambi,
  OpenBet,
  Optimove,
  Salesforce,
  Twilio,
  XtremePush,
  Bragg,
  Gig,
  SwitchAnalytics,
  ChevronDown,
  Evona,
} from '@/assets';

type Logo = {
  src: StaticImageData;
  category: FilterKey;
  label: string;
};

const FILTERS: Filter[] = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'platform',
    label: 'Platform',
  },
  {
    key: 'sportsbook',
    label: 'Sportsbook API',
  },
  {
    key: 'crm',
    label: 'CRM',
  },
  {
    key: 'loyalty',
    label: 'Loyalty & Retention',
  },
  {
    key: 'analytics',
    label: 'Analytics',
  },
];

const LOGOS: Logo[] = [
  {
    src: OpenBet,
    category: 'platform',
    label: 'OpenBet',
  },
  {
    src: Altenar,
    category: 'platform',
    label: 'Altenar',
  },
  {
    src: Jumpman,
    category: 'platform',
    label: 'Jumpman Gaming',
  },
  {
    src: Kambi,
    category: 'sportsbook',
    label: 'Kambi',
  },
  {
    src: BtoBet,
    category: 'sportsbook',
    label: 'BTO',
  },
  {
    src: Arland,
    category: 'sportsbook',
    label: 'Arland',
  },
  {
    src: Optimove,
    category: 'crm',
    label: 'Optimove',
  },
  {
    src: Fastrack,
    category: 'crm',
    label: 'Fast Track',
  },
  {
    src: Bragg,
    category: 'platform',
    label: 'Bragg',
  },
  {
    src: Gig,
    category: 'platform',
    label: 'Gig',
  },
  {
    src: AppsFlyer,
    category: 'analytics',
    label: 'AppsFlyer',
  },
  {
    src: Firebase,
    category: 'analytics',
    label: 'Firebase',
  },
  {
    src: Salesforce,
    category: 'crm',
    label: 'Salesforce',
  },
  {
    src: Twilio,
    category: 'crm',
    label: 'Twilio',
  },
  {
    src: XtremePush,
    category: 'crm',
    label: 'XtremePush',
  },
  {
    src: CaptainUp,
    category: 'loyalty',
    label: 'Captain Up',
  },
  {
    src: SwitchAnalytics,
    category: 'sportsbook',
    label: 'Switch Analytics',
  },
  {
    src: Evona,
    category: 'platform',
    label: 'Evona',
  },
];

export const Logos = () => {
  const [selectedFilters, setSelectedFilters] = useState<FilterKey[]>(['all']);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleFilterChange = (filter: FilterKey) => {
    setSelectedFilters(currentFilters => {
      if (filter === 'all') {
        return ['all'];
      }
      if (currentFilters.includes('all')) {
        return [filter];
      }
      if (currentFilters.includes(filter)) {
        const newFilters = currentFilters.filter(f => f !== filter);
        return newFilters.length > 0 ? newFilters : ['all'];
      }
      return [...currentFilters, filter];
    });
  };

  const filteredLogos = useMemo(() => {
    setIsExpanded(false);
    return LOGOS.filter(({ category }) => selectedFilters.includes('all') || selectedFilters.includes(category));
  }, [selectedFilters]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='w-full relative z-10 flex flex-col items-center md:items-start px-[24px] md:px-[18px]'>
      <Swiper filters={FILTERS} onFilterChange={handleFilterChange} selectedFilters={selectedFilters} />
      <div
        className={clsx(
          'mt-[88px] w-full max-w-[1261px] xl:max-w-[960px] relative min-h-[196px] md:min-h-[122px]',
          isExpanded ? 'h-full' : 'h-[230px] md:h-[150px] overflow-hidden'
        )}>
        <div className='flex flex-wrap start justify-center md:justify-center w-full gap-x-[105px] md:gap-x-[26px] gap-y-[70px] md:gap-y-[37px] relative'>
          <Line
            direction='horizontal'
            className='w-full absolute top-[73px] md:top-[43px] left-[30%] md:left-[50%] opacity-35 z-0'
            gradient='from-transparent via-[#BAD7F759] to-transparent'
          />
          <Line
            direction='horizontal'
            className='absolute top-[140px] left-0 right-0 opacity-35 hidden md:block'
            gradient='from-transparent via-[#BAD7F759] to-transparent'
          />
          {filteredLogos.map(({ src, label }) => (
            <div
              key={`${label}-${selectedFilters.join('-')}`}
              className='flex items-center h-[62px] md:h-[45px] animate-fade-in'>
              <Image className='h-full w-auto' src={src} alt={label} />
            </div>
          ))}
        </div>
      </div>

      <div
        className={clsx(
          'flex justify-center w-full',
          filteredLogos.length > 10 ? 'block' : 'hidden',
          filteredLogos.length > 6 ? 'md:block' : 'hidden'
        )}>
        <button
          className={clsx(
            'text-neutral-0 relative z-30 flex gap-[8px] mx-auto items-center hover:text-secondary group transition-all duration-200 ease-in-out',
            isExpanded ? 'top-[80px] ' : 'top-[50px]'
          )}
          type='button'
          onClick={handleExpand}>
          {isExpanded ? 'See fewer partners' : 'See all of our partners'}
          <Icon
            className={clsx(
              'fill-neutral-0  group-hover:fill-secondary transition-fill duration-200 ease-in-out',
              isExpanded && 'rotate-180'
            )}
            Component={ChevronDown}
            width={17}
            height={17}
          />
        </button>
        {!isExpanded && (
          <div className='w-full h-full absolute -bottom-[338px] md:-bottom-[204px] left-0 bg-gradient-to-t from-neutral-900 via-neutral-900 via-60% to-transparent' />
        )}
      </div>
      <div className='hidden md:block w-full h-[180px] absolute -bottom-[338px] md:-bottom-[204px] left-0 bg-gradient-to-t from-neutral-900 via-neutral-900 via-30% to-transparent' />
    </div>
  );
};
