import Image from 'next/image';
import { Typography } from '@/components';
import { Swiper } from './Swiper';
import {
  QuotesBackground,
  Jacks,
  TenBet,
  Sport888,
  AmazonSlots,
  B2Tech,
  ComeOn,
  CrabSports,
  PrideBet,
  GOAT,
  Lucky7Ventures,
  PremierBet,
  Rhino,
  RooBet,
  Rootz,
  EstrelaBet,
  BetToMax,
} from '@/assets';

const LOGOS = [
  {
    src: Jacks,
    label: 'Jacks',
  },
  {
    src: TenBet,
    label: 'TenBet',
  },
  {
    src: Sport888,
    label: '888sport',
  },
  {
    src: AmazonSlots,
    label: 'AmazonSlots',
  },
  {
    src: B2Tech,
    label: 'B2Tech',
  },
  {
    src: ComeOn,
    label: 'ComeOn',
  },
  {
    src: CrabSports,
    label: 'CrabSports',
  },
  {
    src: PrideBet,
    label: 'PrideBet',
  },
  {
    src: GOAT,
    label: 'GOAT',
  },
  {
    src: Lucky7Ventures,
    label: 'Lucky7Ventures',
  },
  {
    src: PremierBet,
    label: 'PremierBet',
  },
  {
    src: Rhino,
    label: 'Rhino',
  },
  {
    src: RooBet,
    label: 'RooBet',
  },
  {
    src: EstrelaBet,
    label: 'EstrelaBet',
  },
  {
    src: Rootz,
    label: 'Rootz',
  },
  {
    src: BetToMax,
    label: 'BetToMax',
  },
];
const Quotes = () => {
  return (
    <section
      className='relative text-neutral-0 pb-[357px] md:pb-[150px] md:pt-[186px] md:overflow-hidden'
      id='testimonials'>
      <div className='absolute flex flex-col w-full justify-center items-center md:w-[100vw]'>
        <div className='w-full relative -top-[420px] xl:-top-[280px] lg:-top-[250px] md:-top-[140px] sm:-top-[180px] xs:-top-[150px] max-w-[1440px] md:w-[100vw] sm:w-[800px] opacity-20 rounded-full overflow-hidden' />
      </div>
      <Image
        className='object-center 2xl:object-cover absolute z-10 -top-[32vw] right-0 left-0 w-full md:-top-200px lg:-top-[120px] md:min-w-[1135px] md:-left-[30vw] sm:-left-[380px]'
        src={QuotesBackground}
        alt=' '
      />

      <Typography className='text-center relative z-10' tag='h2' size='2xl'>
        What our clients are saying
      </Typography>
      <Swiper />
      <div className='relative z-10 flex flex-wrap gap-x-[90px] gap-y-[44px] md:gap-[26px] mt-[87px] max-w-[1300px] mx-auto justify-center px-[34px]'>
        {LOGOS.map(({ src, label }) => (
          <Image className='h-[55px] md:h-[38px] w-auto' key={label} src={src} alt={label} />
        ))}
      </div>
    </section>
  );
};

export default Quotes;
